#root {
  display: flex;
  background-position-y: center;
  background-image: url(assets/wind-os.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: clamp(300px, 300px, 33%);
  height: 100vh;
}

iframe {
  border-width: 0;
}

@font-face {
  font-family: Digital Dismay;
  src: url("fonts/Digital-Dismay.woff") format("woff"), url("fonts/Digital-Dismay.woff2") format("woff2"); }

@font-face {
  font-family: prstartk;
  src: url("fonts/prstartk.woff") format("woff"), url("fonts/prstartk.woff2") format("woff2"); }

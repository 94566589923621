.menu
  display: flex
  flex-direction: row

  &__item
    font-size: 14px
    background: transparent
    border: 0

    &:focus
      margin-bottom: -1px
      outline: none
      background: rgba(0,0,0, 0.1)